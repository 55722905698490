<template>
	<div>
        <v-row>
			<v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
				<v-sheet color="white" elevation="0" class="pa-0">
					<v-row>
                        <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <display-label label="Project" :text="object.project"></display-label>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <display-label label="Start Date" :text="object.start_date"></display-label>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <display-label label="End Date" :text="object.end_date"></display-label>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <display-label label="Status" :text="object.status.text"></display-label>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <display-label label="Published On" :text="object.published_on"></display-label>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="9" lg="9" xl="9">
                            <display-label list label="Project Managers" v-if="object.managers.length > 0">
                                <div slot="list">
                                    <v-chip small label color="primary" class="mr-2 mt-2" :key="index" v-for="(item, index) in object.managers"><span v-text="item.text"></span></v-chip>
                                </div>
                            </display-label>
                            <display-label label="Project Managers" text="N.A." v-if="object.managers.length == 0"></display-label>
						</v-col>
                    </v-row>
                </v-sheet>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
				<v-sheet color="white" elevation="0" class="pa-0">
					<v-row>
                        <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <display-label label="No. Records" :text="object.quantity"></display-label>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <display-label label="Total Gross" :text="all_total.gross"></display-label>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <display-label label="Total Reimbursement" :text="all_total.reimbursement"></display-label>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <display-label label="Total Additional" :text="all_total.additional"></display-label>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <display-label label="Total Deduction (Before CPF)" :text="object.deduction_before_cpf"></display-label>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <display-label label="Total Deduction (After CPF)" :text="object.deduction_after_cpf"></display-label>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <display-label label="Total Amount" :text="all_total.total_amount"></display-label>
						</v-col>
                    </v-row>
                </v-sheet>
			</v-col>
		</v-row>
		<template v-if="permissions.can_list">
        <v-row>
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="d-flex justify-space-between">
                <v-btn small color="primary" class="elevation-0" :ripple="false" @click="openPublishDialog('P')" v-if="isPublishable()">Publish Payroll</v-btn>
                <v-btn small color="primary" class="elevation-0" :ripple="false" @click="openPublishDialog('UP')" v-if="isUnpublishable()">Unpublish Payroll</v-btn>
                <v-btn small color="primary" class="elevation-0" :ripple="false" @click="openDownloadDialog" v-if="isDownloadable()">Download Payroll Report</v-btn>
                <v-btn small color="primary" class="elevation-0" :ripple="false" @click="openUpdateDialog" v-if="isEditable()">Save Changes</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <hot-table ref="table" :settings="settings" :width="getTableWidth()"></hot-table>
            </v-col>
        </v-row>
        </template>
        <update-dialog ref="updateDialog" @updated="updateItems"></update-dialog>
        <publish-dialog ref="publishDialog" @published="updateObject"></publish-dialog>
        <download-dialog ref="downloadDialog" @downloaded="handleDownloaded"></download-dialog>
        <simple-action-dialog :has-cancel="false" ref="loadingDialog" title="Preparing..." text="System is preparing the report now. Please be patient." confirm-text="OK"></simple-action-dialog>
		<message-notifier ref="notifier"></message-notifier>
	</div>
</template>


<script>

import { HotTable } from '@handsontable/vue'
import PayrollProjectPublishDialog from './PayrollProjectPublishDialog'
import PayrollProjectUpdateDialog from './PayrollProjectUpdateDialog'
import PayrollProjectDownloadDialog from './PayrollProjectDownloadDialog'
import MessageNotifier from '../../../shared/MessageNotifier.vue'
import DisplayLabel from '../../../shared/DisplayLabel.vue'
import SimpleActionDialog from '../../../shared/SimpleActionDialog.vue'


export default {
	name: 'PayrollProjectInfo',
	components: {
        HotTable,
        publishDialog: PayrollProjectPublishDialog,
        updateDialog: PayrollProjectUpdateDialog,
        downloadDialog: PayrollProjectDownloadDialog,
        messageNotifier: MessageNotifier,
        displayLabel: DisplayLabel,
        simpleActionDialog: SimpleActionDialog,
	},
	data () {
		return {
			loading: false,
			object: {
                project: '',
                start_date: '',
                end_date: '',
                status: { text: '', value: ''},
                published_on: '',
                is_published: false,
                is_closed: true,
                quantity: '',
                gross: '',
                reimbursement: '',
                additional: '',
                deduction_before_cpf: '',
                deduction_after_cpf: '',
                total_amount: '',
                managers: [],
			},
            all_total: {
                gross: '0.0',
                reimbursement: '0.0',
                additional: '0.0',
                total_amount: '0.0',
            },
            permissions: {
                is_admin: false,
                can_list: false,
                can_download: false,
                can_edit: false
            },
			selectedItems: {},
			selectedItemIds: [],
			settings: {
                licenseKey: 'non-commercial-and-evaluation',
                data: null,
                height: 'auto',
                startRows: 250,
                rowHeaders: true,
                colHeaders: true,
                stretchH: 'all',
                minSpareRows: 1,
                columns: [
                    { data: 'name', type: 'text', readOnly: true, className: 'htLeft htMiddle' },
                    { data: 'nric', type: 'text', readOnly: true, className: 'htCenter htMiddle' },
                    { data: 'email', type: 'text', readOnly: true, className: 'htLeft htMiddle' },
                    { data: 'client', type: 'text', readOnly: true, className: 'htCenter htMiddle' },
                    { data: 'project', type: 'text', readOnly: true, className: 'htCenter htMiddle' },
                    { data: 'work_date', type: 'text', readOnly: true, className: 'htCenter htMiddle' },
                    { data: 'outlet', type: 'text', readOnly: true, className: 'htCenter htMiddle' },
                    { data: 'working_times', type: 'text', readOnly: true, className: 'htCenter htMiddle' },
                    { data: 'hourly_rate', type: 'numeric', editor: "numeric", readOnly: false, className: 'htCenter htMiddle' },
                    { data: 'mon', type: 'text', readOnly: true, className: 'htCenter htMiddle' },
                    { data: 'tue', type: 'text', readOnly: true, className: 'htCenter htMiddle' },
                    { data: 'wed', type: 'text', readOnly: true, className: 'htCenter htMiddle' },
                    { data: 'thu', type: 'text', readOnly: true, className: 'htCenter htMiddle' },
                    { data: 'fri', type: 'text', readOnly: true, className: 'htCenter htMiddle' },
                    { data: 'sat', type: 'text', readOnly: true, className: 'htCenter htMiddle' },
                    { data: 'sun', type: 'text', readOnly: true, className: 'htCenter htMiddle' },
                    { data: 'rest_hour', type: 'text', readOnly: true, className: 'htCenter htMiddle' },
                    { data: 'working_hours', type: 'text', readOnly: true, className: 'htCenter htMiddle' },
                    { data: 'gross', type: 'text', readOnly: true, className: 'htCenter htMiddle' },
                    { data: 'reimbursement', type: 'numeric', editor: "numeric", readOnly: true, className: 'htCenter htMiddle' },
                    { data: 'commission', type: 'numeric', editor: "numeric", readOnly: true, className: 'htCenter htMiddle' },
                    { data: 'project_fee', type: 'numeric',editor: "numeric", readOnly: true, className: 'htCenter htMiddle' },
                    { data: 'performance', type: 'numeric',editor: "numeric", readOnly: true, className: 'htCenter htMiddle' },
                    { data: 'overtime', type: 'numeric', editor: "numeric", readOnly: true, className: 'htCenter htMiddle' },
                    { data: 'public_holiday', type: 'numeric',editor: "numeric", readOnly: true, className: 'htCenter htMiddle' },
                    { data: 'others', type: 'numeric',editor: "numeric", readOnly: true, className: 'htCenter htMiddle' },
                    { data: 'deduction_before_cpf', type: 'numeric',editor: "numeric", readOnly: true, className: 'htCenter htMiddle' },
                    { data: 'deduction_after_cpf', type: 'numeric',editor: "numeric", readOnly: true, className: 'htCenter htMiddle' },
                    { data: 'total_amount', type: 'text', readOnly: true, className: 'htCenter htMiddle' },
                ],
                afterChange: (changes) => {
                    if(changes && changes.length > 0){
                        const selectedItem = this.settings.data[changes[0][0]]
                        const oldValue = changes[0][2], newValue = changes[0][3]

                        if(isNaN(newValue)) {
                            return
                        }

                        if(this.selectedItemIds.indexOf(selectedItem.id) === -1){
                            this.selectedItemIds.push(selectedItem.id)
                            this.selectedItems[selectedItem.id] = {id: selectedItem.id}
                        }
                        if(oldValue !== newValue){
                            this.selectedItems[selectedItem.id][changes[0][1]] = changes[0][3]
                        }
                    }
                }
			},
		}
	},
	computed: {
        publishDialog() {
            return this.$refs.publishDialog
        },
        updateDialog() {
            return this.$refs.updateDialog
        },
        downloadDialog() {
            return this.$refs.downloadDialog
        },
        loadingDialog() {
            return this.$refs.loadingDialog
        },
        notifier() {
            return this.$refs.notifier
        }
	},
	mounted: function() {
		this.get()
	},
	methods: {
        get: function() {
			this.loading = true
			this.items = []
			this.$store.dispatch('epanel/payroll/getPayrollProjectPayments', this.$route.params).then((response) => {
                this.getAllTotal(response.data.items)
                this.object = response.data.object
                this.permissions = response.data.permissions
                this.settings.colHeaders = response.data.headers
                this.settings.data = response.data.items
                this.updateHotTable()
				this.loading = false
			}).catch(() => {
                this.loading = false
			})
		},
        getAllTotal: function(payrollShifts) {
            let gross = []
            let additional = []
            let total = []

            payrollShifts.map(payrollShift => {
                gross.push(payrollShift.gross)
                additional.push(payrollShift.commission + payrollShift.project_fee)
                total.push(payrollShift.total_amount)
            })

            let sum_of_gross = gross.reduce((a, b) => a + b, 0)
            let sum_of_additional = additional.reduce((a, b) => a + b, 0)
            let sum_of_total = total.reduce((a, b) => a + b, 0)

            this.all_total.gross = sum_of_gross.toString()
            this.all_total.additional = sum_of_additional.toString()
            this.all_total.total_amount = sum_of_total.toString()
        },
        getTableWidth: function(){
            return window.innerWidth - 48
        },
        openPublishDialog: function(status) {
            if(this.isPublishable() || this.isUnpublishable()){
                this.publishDialog.updateObject({ status: status })
                this.publishDialog.open()
            }
        },
		openNotifier: function(message) {
            this.notifier.updateText(message)
            this.notifier.open()
        },
        updateItems: function(item) {
            this.selectedItems = {}
            this.selectedItemIds = []
            this.get()
            this.openNotifier(item.message)
        },
        updateObject: function(item) {
            this.$set(this, 'object', item.object)
            this.updateHotTable()
            this.openNotifier(item.message)
        },
        handleDownloaded: function(){
            this.loadingDialog.open()
        },
        updateHotTable: function() {
            this.updateHotTableColumns()
            this.$refs.table.hotInstance.updateSettings(this.settings)
        },
        updateHotTableColumns: function() {
            const isEditable = !this.isEditable()?true:false
            const columns = [8, 20, 21, 22, 23, 24, 25, 26, 27]
            columns.forEach((column) => {
                this.settings.columns[column]['readOnly'] = isEditable
            })
        },
        openUpdateDialog: function() {
            if(this.isEditable()){
                this.updateDialog.updateObject({items: this.cleanSelectedItems()})
                this.updateDialog.open()
            }
        },
        openDownloadDialog: function() {
            if(this.isDownloadable()){
                const object = { payroll: this.$route.params['id'] }
                this.downloadDialog.updateObject(object)
                this.downloadDialog.open()
            }
        },
        cleanSelectedItems: function() {
            var items = []

            if(Object.keys(this.selectedItems).length === 0) {
                return items
            }

            for(const key in this.selectedItems){
                items.push(this.selectedItems[key])
            }

            return items
        },
        isDownloadable: function() {
            if(!this.permissions.can_download){
                return false
            }

            if(!this.object.is_published){
                return false
            }

            return true
        },
        isEditable: function() {
            if(!this.permissions.can_edit){
                return false
            }

            if(!this.permissions.is_admin && this.object.is_closed){
                return false
            }

            if(this.object.is_published){
                return false
            }

            return true
        },
        isPublishable: function(){
            if(!this.permissions.can_publish) {
                return false
            }

            if(!this.permissions.is_admin && this.object.is_closed){
                return false
            }

            if(this.object.is_published){
                return false
            }

            return true
        },
        isUnpublishable: function(){
            if(!this.permissions.can_publish) {
                return false
            }

            if(!this.permissions.is_admin && this.object.is_closed){
                return false
            }

            if(!this.object.is_published){
                return false
            }

            return true
        },
	}
}

</script>


<style scoped>

/deep/ .ht_clone_top.handsontable {
    z-index: 0 !important;
}

</style>