<template>
    <v-dialog persistent max-width="764px" v-model="dialog">
        <v-card>
            <v-card-title v-text="getTitleText()"></v-card-title>
            <v-card-text class="pb-0">
                <v-row>
                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pb-0" v-if="errors && errors.message">
                        <v-alert dense outlined type="error" class="mb-0"><span v-text="errors.message"></span></v-alert>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <v-alert dense class="text-subtitle-1" type="warning">
                            <span v-if="!isPublished()">Are you sure that you want to publish this payroll? Once you publish this payroll, you only can view this payroll.</span>
                            <span v-if="isPublished()">Are you sure that you want to unpublish this payroll?</span>
                        </v-alert>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="text-right pr-6 pl-6">
                <v-spacer></v-spacer>
                <v-btn text @click="close">Cancel</v-btn>
                <v-btn text color="primary" :loading="loading" @click="save"><span v-text="getConfirmText()"></span></v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>

export default {
    name: 'PayrollProjectPublishDialog',
    data() {
        return {
            dialog: false,
            loading: false,
            object: { status: '' },
            errors: {}
        }
    },
    methods: {
        open: function() {
            this.dialog = true
        },
        close: function() {
            this.dialog = false
            this.loading = false
            this.errors = {}
        },
        save: function() {
            this.loading = true

            this.$store.dispatch('epanel/payroll/publishPayrollProject', { id: this.$route.params['id'], object: this.object }).then((response) => {
                this.close()
                this.$emit('published', { object: response.data.object, message: response.message })
            }).catch((errors) => {
                this.errors = errors.data?errors.data.errors:errors
                this.loading = false
            })
        },
        updateObject: function(item) {
            this.object = item
        },
        isPublished: function() {
            return this.object.status === 'UP'?true:false
        },
        getTitleText: function() {
            return this.isPublished()?'Unpublish Payroll':'Publish Payroll'
        },
        getConfirmText: function() {
            return this.isPublished()?'Unpublish':'Publish'
        }
    }
}

</script>